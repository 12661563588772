import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import NewsFetcherForm from './NewsFetcherForm';

const Projects = ({ theme }) => {
    const [newsData, setNewsData] = useState(null);
    const [loading, setLoading] = useState(false);

    const projectsStyle = theme === 'dark'
        ? {
            background: 'linear-gradient(to right top, #101112, #0c141d, #071627, #041831, #05183b, #041d42, #03214a, #002651, #003056, #003a58, #004359, #084b58)',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            padding: '100px 15px', // Adjust padding for smaller screens
        }
        : {
            background: 'linear-gradient(to right top, #86c8f9, #6ad8f8, #65e5eb, #80efd4, #abf6bb, #c0f4b4, #d2f2b0, #e2f0af, #dbe9b8, #d5e1c0, #d1d9c8, #cfd0ce)',
            color: '#105D60',
            display: 'flex',
            alignItems: 'center',
            padding: '100px 15px', // Adjust padding for smaller screens
        };

    const fetchNewsData = async (url, selector) => {
        setLoading(true);
        try {
            const response = await fetch(`https://103.59.160.21:8991/newsfetcher/api/news?newsUrl=${encodeURIComponent(url)}&newsSelector=${encodeURIComponent(selector)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                    // Tambahkan header lain jika diperlukan
                }
            });
            const data = await response.json();
            setNewsData(data);
        } catch (error) {
            console.error('Error fetching news:', error);
        }
        setLoading(false);
    };
        

    return (
        <section className="projects" style={projectsStyle}>
            <Container>
            <h2>Article scraper</h2>
                <div class="row">
                    <div class="col-4">
                        <NewsFetcherForm onSubmit={fetchNewsData} />
                    </div>
                    <div class="col-8">
                        <h4>Result:</h4>
                            {loading && <p>Fetching data...</p>}

                            {newsData && (
                                <div>
                                    <p>{newsData.data}</p>
                                </div>
                            )}
                    </div>
                </div>
            </Container>
        </section>
    );
}

export default Projects;
